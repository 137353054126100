@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@300;700&display=swap');

@font-face {
  font-family: 'Founders Grotesk';
  src: url(./assets/founders-grotesk-light.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Founders Grotesk';
  src: url(./assets/founders-grotesk-regular.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Founders Grotesk';
  src: url(./assets/founders-grotesk-medium.woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Founders Grotesk';
  src: url(./assets/founders-grotesk-bold.woff2) format('woff2');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  height: 100%;
  background-color: #fff;
  font-family: 'Founders Grotesk', sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(./assets/noise500@2x-min.png) repeat center center fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

p, ul {
  margin-top: 0;
  margin-bottom: 0;
}

ul {
  padding-inline-start: 18px;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 1
}